<template>
	<div class="container">
		<div class="w1200">
			<div class="nav_top">当前位置：<router-link :to="{path:'/'}">首页</router-link> > <router-link :to="{path:'/reservation'}">预约服务</router-link> > {{getIndex(type)}}</div>
			<img src="@/assets/images/step3.png"/>
			<div class="content">
				<div>
					<div class="info flex">
						<div class="left">
							<div class="page">
								<p class="left_title flex_a"><img src="@/assets/images/dian.png"/>参观日期</p>
								<p>预约日期：{{ruleForm.bookingDate}}</p>
								<p>预约时段：{{ruleForm.bookingTime}}</p>
							</div>
							<div class="page">
								<p class="left_title flex_a"><img src="@/assets/images/dian.png"/>预约信息</p>
								<p>预约类型：{{getIndex(type)}}</p>
								<p v-if="type == 2">参观单位：{{ruleForm.visitingUnit}}</p>
								<p>{{type == 1 ? '领队' :(type == 2 ? '联系人': '')}}姓名：{{ruleForm.name}}</p>
								<p>{{type == 2 ? '联系人' : ''}}身份证号：{{ruleForm.idNo}}</p>
								<p>{{type == 2 ? '联系人' : ''}}手机号码：{{ruleForm.phone}}</p>
								<p v-if="type == 2">公函/介绍信：
									<el-popover
										placement="right"
										width="600"
										trigger="click">
										<img class="coverimg-big" :src="ruleForm.officialLetter"/>
										<span slot="reference">公函</span>
									</el-popover>
								</p>
								<p v-if="type == 1 || type == 2">团队参观人数：{{ruleForm.teamNums}}</p>
								<p v-if="type == 1 || type == 2">备注：{{ruleForm.remark}}</p>
							</div>
						</div>
<!--						<div class="right">-->
<!--							<p class="left_title flex_a"><img src="@/assets/images/dian.png"/>讲解信息</p>-->
<!--							<div class="all">-->
<!--								<p>{{ruleForm.explainProject == 0 ? '无需讲解' : (ruleForm.explainProject == 1 ? '全园讲解' : '纪念馆讲解') }}</p>-->
<!--								&lt;!&ndash; <p>单价：{{this.type == 2 ? '免费' : (ruleForm.explainProject == 0 ? '免费' : (ruleForm.explainProject == 1 ? '￥200' : '￥100'))}}</p> &ndash;&gt;-->
<!--							</div>-->
<!--							&lt;!&ndash; <div class="payment">需支付：<label>{{this.type == 2 ? '￥0' : (ruleForm.explainProject == 0 ? '￥0' : (ruleForm.explainProject == 1 ? '￥200' : '￥100'))}}</label></div> &ndash;&gt;-->
<!--						</div>-->
					</div>
					<div class="btn">
						<el-button type="primary" @click="handleSure">确认预约</el-button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import urlobj from '@/api/baseurl.js'
	export default {
		data() {
			return {
				index: '',
				type: null,
				ruleForm: {
					usersId: '',
					visitingUnit: '',
					name: '',
					idNo: '',
					phone: '',
					commentator: '1',
					imageUrl: '',
					explainProject: 1,
					bookingDate: '',
					bookingPeriodId: '',
					bookingTime: '',
					teamNums: '',
					officialLetter: '',
					officialLetterName: '',
					remark: '',
				},




			}
		},
		created() {

		},
		mounted() {
			this.type = this.$route.query.type
			this.ruleForm = JSON.parse(sessionStorage.getItem('form'))
		},
		methods: {
			getIndex(index) {
				if(index == 0) return '个人预约'
				if(index == 1) return '团队预约'
				if(index == 2) return '政务接待'
			},
			getPrice(price,flag) {
				if(price == 0) return flag ? 0 : '免费'
				if(price == 1) return this.type == 2 && !flag ? '免费' : '￥'+200
				if(price == 2) return this.type == 2 && !flag ? '0' : '￥'+100
			},
			handleSure() {
				let formData = new FormData()
					formData.append('usersId', this.ruleForm.usersId),
					formData.append('type', this.type),
					formData.append('bookingDate', this.ruleForm.bookingDate),
					formData.append('bookingPeriodId', this.ruleForm.bookingPeriodId),
					formData.append('name', this.ruleForm.name),
					formData.append('idNo', this.ruleForm.idNo),
					formData.append('phone', this.ruleForm.phone),
					formData.append('explainProject', this.ruleForm.explainProject),
					formData.append('teamNums', this.ruleForm.teamNums),
					formData.append('visitingUnit', this.ruleForm.visitingUnit),
					formData.append('officialLetter', this.ruleForm.officialLetter),
					formData.append('officialLetterName', this.ruleForm.officialLetterName),
					formData.append('remark', this.ruleForm.remark),
				this.$http({
					url: `/bookingRecordApi/editSubmitBooking`,
					method: "POST",
					data: formData
				}).then((res) => {
					if(res.state == 10200){
						this.$router.push({ path: "/Reservation/step_four",query:{type:this.type, money: this.type == 2 ? 0 : this.ruleForm.explainProject}});
					}

				});

			}



		}
	}
</script>

<style lang="scss" scoped>
.container{
	.w1200{
		min-height: calc(100vh - 354px);
		background: #FFFFFF;
		padding-bottom: 60px;
		>img {
			margin: 0 auto;
			margin-bottom: 20px;
			margin-top: 50px;
		}
		.content{
			min-height: 453px;
			width: 950px;
			background: url('../../assets/images/kuang.png') no-repeat;
			background-size: 100% 100%;
			margin: 0 auto;
			margin-top: 30px;
			padding: 40px 60px;
			font-size: 16px;
			color: #595E63;
			display: flex;
			align-items: center;
			justify-content: center;
			.left_title{
				font-weight: bold;
				font-size: 18px;
				margin-bottom: 15px;
				img{
					margin-right: 10px;
				}
			}
			.left{
				.page{
					margin-bottom: 30px;
					span{
						background: #3485B9;
						color: #FFF;
						font-size: 12px;
						border-radius: 5px;
						padding: 5px 10px;
						cursor: pointer;
					}
					p{
						line-height: 27px;
					}
				}
			}
			.right{
				margin-left: 100px;
				.all{
					width: 380px;
					border: 1px dashed #DCDCDC;
					padding: 15px 20px;
					p{
						&:nth-of-type(1){
							font-size: 24px;
							margin-bottom: 10px;
						}
					}
				}
				.payment{
					text-align: center;
					background: #F1F1F1;
					color: #595E63;
					margin-top: 20px;
					padding: 10px 0;
					label{
						color: #A5343C;
					}
				}

			}
			.btn{
				text-align: center;
				margin-top: 20px;
			}

		}
	}
}

</style>
